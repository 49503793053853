<template>
	<div class="home">
		<section class="is-flex is-centered-in-middle is-column" id="start">
			<div class="is-row">
				<h1 class="has-justified-content-center mb-10">
					Vi effektiviserar företags&shy;rådgivning
				</h1>
				<div class="has-color-black subtitle">
					Med egenutvecklad programvara möjliggör vi effektiv
					företags&shy;rådgivning baserad på officiella bokslut eller
					importerade SIE-filer från affärssystemet
				</div>

				<div class="is-flex has-justified-content-center hide-on-mobile">
					<a href="#advisors">
						<div class="is-row button has-bg-contrast m-20">
							För rådgivare
						</div>
					</a>
					<a href="#companies">
						<div class="is-row button has-bg-secondary has-color-white m-20">
							För företagare
						</div>
					</a>
				</div>
			</div>
		</section>  

		<section class="is-flex has-bg-light" id="advisors">
			<div class="wrapper is-flex">
				<div class="is-column has-width-three hide-on-mobile">
					<img src="@/assets/img/bk_value.png" />
				</div>
				<div class="is-column has-width-two pl-20 has-text-left">
					<h2 class="mb-10">För rådgivare</h2>
					<p>
						Analyscenter är vår egenutvecklade programvara som används av
						ledande företags&shy;rådgivare och revisions&shy;byråer för att
						snabbt förvandla officiella bokslut eller importerade SIE-filer till
						färdiga analyser och pedagogiska presentationer.
					</p>
					<p class="has-fontweight-600">
						För mer information besök
						<a
							href="https://analyscenter.se"
							target="_blank"
							class="underscore-text"
							>analyscenter.se</a
						>
						eller kontakta oss för en personlig demonstration.
					</p> 
				</div>
			</div>
		</section>

		<section class="is-flex has-bg-lightgrey" id="companies">
			<div class="wrapper is-flex">
				<div class="is-column has-width-three hide-on-mobile">
					<img class="has-border" src="@/assets/img/bk_minabolag.png" />
				</div>
				<div class="is-column has-width-two pl-20 has-text-left">
					<h2 class="mb-10">För företagare</h2>
					<p>
						<span class="bold">MinaBolag</span> är en tjänst under utveckling som på ett par klick förvandlar aktuell bokföringsdata till snygga månadspresentationer.
					</p>


					<p class="has-fontweight-600">
						För mer information besök
						<a
							href="http://beta.minabolag.se"
							target="_blank"
							class="underscore-text"
							>beta.minabolag.se</a
						>
					</p>
				</div>
			</div>
		</section>

		<section class="is-flex has-color-light" id="about">
			<div class="wrapper is-flex">
				<div class="is-column has-width-three has-text-left pr-20 hide-on-mobile">
					<h2 class="mb-10">Om oss</h2>
					<p>
						Med ena benet i finansiell analys och det andra i innovativa
						IT-lösningar grundades Bolagskompassen 2013 för att visualisera och
						göra ekonomisk företagsanalys lättebegriplig för alla.
					</p>
					<p>
						På Bolagskompassen brinner vi för att skapa effektiva lösningar för
						att analysera och presentera ekonomisk information för såväl
						rådgivare som företagare. Med passion och precision utvecklar vi
						skalbara webblösningar för både egen och andras användning.
					</p>
					<p>
						Bolagskompassen ägs och drivs av Kristoffer Norén och Martin
						Woxneryd.
					</p>
				</div>
				<div class="is-column has-width-two pl-20 has-text-left">
					<h2 class="mb-10">Kontakt</h2>
					<p>Bolagskompassen AB <br />Org.nr. 556934-2024</p>
					<p>
						Adress
						<br />Storgatan 35<br />411 38 Göteborg
					</p>
					<p>
						E-post<br />
						<a href="mailto:info@bolagskompassen.se" class=""
							>info@bolagskompassen.se</a
						>
					</p>
					<p>Telefon<br />0768-67 67 78</p>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		name: "Home",
	};
</script>

<style lang="scss" scoped>
	router-link {
		text-decoration: none;
	}

	a {
		text-decoration: none;
		color: $secondary;
	}

	.underscore-text {
		text-decoration: underline;
	}

	body {
		background-color: $darkgrey;
	}

	html {
		scroll-behavior: smooth;
		scroll-margin-top: 4rem;
	}

	img {
		width: 100%;
		object-fit: cover;
		aspect-ratio: 16 / 9;
		border: 1px solid $lightgrey;
	}

	.home {
		width: 80vw;
		position: absolute;
		left: 20vw;
	}

	section {
		position: relative;
		min-height: 100vh;
		width: 100%;
		scroll-snap-align: start; // Fungerar inte
	}

	h1 {
		font-size: 3rem;
		color: $black;
	}

	h2 {
		font-size: 2rem;
		font-weight: 500;
	}

	.button {
		padding: 1rem;
		border: 1px solid $darkgrey;
		border-radius: 0.25rem;
		width: 20vw;
		transition: 0.5s;
		font-weight: 600;
		text-transform: none;

		&:hover {
			cursor: pointer;
			border-color: $black;
			box-shadow: 0 0 1rem 0 $grey;
			//			transform: scale(1.05);
		}

		&.has-bg-primary {
			&:hover {
				background-color: lighten($primary, 10%);
			}
		}

		&.has-bg-contrast {
			color: $black;

			&:hover {
				background-color: lighten($contrast, 10%);
			}
		}

		&.has-bg-secondary {
			color: $white;

			&:hover {
				background-color: lighten($secondary, 10%);
			}
		}
	}

	.is-centered-in-middle {
		justify-content: center;
		align-items: center;
	}

	.subtitle {
		margin: 2rem 20%;
		line-height: 1.6;
		font-size: 1.25rem;
		font-weight: 400;
		color: $darkgrey;
	}

	.has-border {
		border: 1px solid $darkgrey;
		box-shadow: 0 0 1rem 0 $grey;
		transition: 0.5s;
		&:hover {
			transform: scale(1.5);
			box-shadow: 0 0 2rem 0 black;
		}
	}

	#start {
		background-color: $lightgrey;
		background-image: url(../assets/img/bk_compass.png);
		background-size: cover;
		background-blend-mode: screen;
		background-attachment: fixed;
		background-position: center;
	}

	#test {
		position: absolute;
		bottom: 50px;
	}
	#about {
		background-color: $dark;
		background-image: url(../assets/img/cover.jpg);
		background-size: cover;
		background-blend-mode: multiply;
		background-attachment: fixed;
		background-position: center;

		a {
			color: $light;
			transition: 0.5s;
			&:hover {
				color: $contrast;
			}
		}
	}

	@media screen and (max-width: 1400px) {
  .home {
		width: 100vw;
		position: absolute;
		left: 0;
  }
}

	@media screen and (max-width: 1000px) {

		h1 {
			font-size: 2.5rem;
		}
		.hide-on-mobile {
			display: none;
		}
		.is-column {
			flex-direction: row;
		}

}


</style>
